@import "../../../var";

.company-search {
  /* Definitions */
  $companyTickerWidth: 80px;
  $companyMarketWidth: 120px;
  $companySicWidth: 380px;
  $companyTypeWidth: 100px;
  $companyClassificationWidth: 200px;

  $worksheetSubWidth: 100px;
  $worksheetBrokerWidth: 100px;
  $worksheetLocationWidth: 100px;
  $worksheetTypeWidth: 80px;
  $worksheetPolicyNoWidth: 120px;
  $worksheetDateWidth: 130px;
  $worksheetStatusWidth: 195px;
  $worksheetDeleteWidth: 30px;

  margin-bottom: 50px;

  h3 {
    font-size: 1.4em;
    margin: 2em 0 1em 0;
  }

  h2.no-data {
    text-align: center;
    color: $grey;
  }

  #worksheets-filters {
    & > div {
      display: table-cell;
      padding-right: 15px;
      padding-bottom: 15px;
    }
  }

  ul#worksheet-tabs {
    padding: 0;
    & > li {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
      &.focused {
        font-weight: bold;
        a {
          border-bottom: 3px solid black;
        }
      }
      display: inline-block;
      padding: 20px 30px 20px 0;
      font-size: 30px;
    }
  }

  .search-result-head {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 0.85em;
    display: flex;

    .company-name {
      flex: 1;
      font-family: $fontSemiBold;
    }
    .company-ticker {
      width: $companyTickerWidth;
    }
    .company-market {
      width: $companyMarketWidth;
    }
    .company-sic {
      width: $companySicWidth;
    }
    .company-type {
      width: $companyTypeWidth;
    }
    .company-classification {
      width: $companyClassificationWidth;
    }
    .worksheet-sub {
      width: $worksheetSubWidth;
    }
    .worksheet-company-name {
      flex: 1;
    }
    .worksheet-broker {
      width: $worksheetBrokerWidth;
    }
    .worksheet-location {
      width: $worksheetLocationWidth;
    }
    .worksheet-type {
      width: $worksheetTypeWidth;
    }
    .worksheet-policyNo {
      width: $worksheetPolicyNoWidth;
    }
    .worksheet-date {
      width: $worksheetDateWidth;
    }
    .worksheet-status {
      width: $worksheetStatusWidth;
    }
    .worksheet-remove {
      width: $worksheetDeleteWidth;
    }
  }

  .worksheet-state {
    .tag {
      width: 100%;
    }
  }

  .search-result {
    .worksheet-actions {
      visibility: hidden;
      position: absolute;
      top: -15px;
      opacity: 0;
      overflow: hidden;
      font-size: 14px;
      display: flex;
      justify-content: right;
      text-transform: none;
      transition: none;
      margin-left: auto;
      span {
        transition: none !important;
      }
      svg {
        top: 5px;
        position: relative;
        height: 22px;
        width: 22px;
        path {
          transition: none;
        }
      }
      .worksheet-action-btn {
        transition: none;
        border: 1px solid black;
        border-radius: 5px;
        text-align: center;
        // width: 80px;
        margin-left: 3px;
        margin-right: 3px;
        padding: 0px 8px 4px 4px;
      }
    }
    &:hover {
      .worksheet-state {
        display: none;
      }
      .worksheet-actions {
        margin-left: auto;
        position: relative;
        opacity: 1;
        top: 0;
        visibility: visible;
        transition: none;

        span,
        a {
          transition: none !important;
        }

        .worksheet-action-btn {
          &:hover {
            border: 1px solid $primary;
            color: $primary;
            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
    cursor: pointer;
    .company-name {
      @include flex(1, $cardHeight - 2);
      font-family: $fontSemiBold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .company-ticker {
      @include flex(unset, $cardHeight - 2);
      width: $companyTickerWidth;
    }
    .company-market {
      @include flex(unset, $cardHeight - 2);
      width: $companyMarketWidth;
    }
    .company-sic {
      @include flex(unset, $cardHeight - 2);
      width: $companySicWidth;
    }
    .company-classification {
      @include flex(unset, $cardHeight - 2);
      width: $companyClassificationWidth;
    }
    .company-type {
      @include flex(unset, $cardHeight - 2);
      width: $companyTypeWidth;
    }
    .worksheet-sub {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetSubWidth;
    }
    .worksheet-company-name {
      @include flex(1, $cardHeight - 2);
    }
    .worksheet-broker {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetBrokerWidth;
    }
    .worksheet-location {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetLocationWidth;
    }
    .worksheet-type {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetTypeWidth;
    }
    .worksheet-policyNo {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetPolicyNoWidth;
    }
    .worksheet-date {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetDateWidth;
    }
    .worksheet-status {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetStatusWidth;
      text-transform: uppercase;
      box-sizing: content-box;
      padding-left: 5px;
      padding-right: 5px;
    }
    .worksheet-remove {
      @include flex(unset, $cardHeight - 2);
      width: $worksheetDeleteWidth;
      svg:hover {
        fill: $primary;
      }
    }
  }
}

.confirm-remove-modal {
  .header {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .footer {
    position: absolute;
    bottom: 16px;
    right: 16px;
    font-size: 13px;
    .button {
      display: inline-block;
      margin-left: 28px;
    }
  }
}
