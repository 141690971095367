@import "../../../var";

.riskPredictorContainer {
  font-family: "PT Mono";
  display: flex;
  overflow: visible;
  margin: auto;
  .riskLines {
    width: 80%;
    left: 10%;
    position: relative;
    margin-top: 10px;
    .riskLineSolid {
      position: absolute;
      left: 5%;
      width: 90%;
      border-top: 2px solid #777;
      z-index: 190;
    }
    .riskLineDots {
      position: absolute;
      width: 100%;
      border-top: 2px dotted #ddd;
    }
    .riskLinesAnimation {
      font-size: 10px;
      text-align: left;
      padding-top: 10px;
      position: relative;
      & > div {
        -webkit-transition: all 0.15s ease-in;
        -moz-transition: all 0.15s ease-in;
        -ms-transition: all 0.15s ease-in;
        -o-transition: all 0.15s ease-in;
        transition: all 0.15s ease-in;
        position: relative;
        top: 2px;
        &.risk-mouseover {
          opacity: 1;
          top: 0px;
        }
      }
      span {
        width: 50px;
        text-align: center;
        position: absolute;
      }
    }
  }

  .riskLinesInner {
    position: relative;
  }

  .verticalLine {
    position: absolute;
    border-left: 2px solid #777;
    width: 1px;
    height: 10px;
    top: -4px;
    z-index: 199;
    &.redLine {
      z-index: 200;
      top: -6px;
      height: 15px;
      border-left: 3px solid $red;
    }
  }
  .riskPredictor {
    position: relative;
    min-width: 450px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    [tooltip] {
      margin: 20px;
      position: relative;
    }
    [tooltip]::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      transform: translateX(-50%);
      border-width: 4px 6px 0 6px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
      z-index: 100;
    }
    [tooltip]::after {
      content: attr(tooltip);
      position: absolute;
      left: 0;
      top: 2px;
      transform: translateX(-50%) translateY(-100%);
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
      color: #fff;
      padding: 4px 2px;
      font-size: 12px;
      min-width: 80px;
      pointer-events: none;
    }

    .tipContainer {
      position: relative;
      margin-left: 0;
      width: 1px !important;
      .tip {
        position: absolute;
        padding: 3px 17px;
        background-color: black;
        opacity: 0.9;
        font-size: 13px;
        color: white;

        &:after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          bottom: -6px;
          left: 50%;
          margin-left: -8px;
          border-top-color: #222;
          border-top-style: solid;
          border-top-width: 6px;
        }
      }
    }

    hr {
      height: 1px;
      border: 0;
      background-color: #ddd;
      margin: 17px 0;
    }

    svg {
      width: 10px;
      height: 10px;
      position: relative;
      top: 2px;
      cursor: pointer;
    }
    .step {
      font-size: 0.8em;
      position: absolute;
      bottom: 0;
      white-space: nowrap;
      &:nth-of-type(2) {
        display: none;
      }
      .step-label-value {
        margin-left: 5px;
      }
      svg {
        fill: $grey;
      }

      &:last-child {
        svg {
          float: right;
          position: relative;
          top: -10px;
        }
      }

      .step-label {
        padding-top: 10px;
      }

      &:not(:first-child):not(:last-child) {
        .step-label {
          position: relative;
          left: -50%;
        }
      }
    }

    .value-label {
      margin-bottom: 3px;
      font-size: 0.9em;
    }
    .value {
      display: initial;
      position: absolute;
      top: -13px;
      width: 100%;
      z-index: 2;
      left: 0;
      color: $primary;
      font-weight: bold;
      .value-label {
        text-align: right;
      }
      svg {
        width: 15px;
        height: 15px;
        fill: $primary;
        float: right;
      }
    }
    .avg {
      position: absolute;
      top: 12px;
      z-index: 1;
      svg {
        fill: $pink;
      }
    }
  }
}
