@import "../../../var";

.ipopy1 {
    .title {
        padding-bottom: 28px;
    }

    .common-element {
        input {
            padding: 3px 5px;
            border: 1px solid #ddd;
            border-radius: 3px;
        }
    }

    .select {
        input {
            border: none;
        }
    }

    .in-progress {
        td:nth-child(3) {
            .main-td-content {
                color: $grey;
                border: 4px solid $grey;
                border-top: 4px solid $darkblue;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                animation: spin 1.25s linear infinite;
            }
        }
    }

    .in-error {
        td:nth-child(3) {
            .main-td-content {
                color: $red;
            }
            .main-td-content:after {
                content: 'Evaluation failed';
            }
        }
    }

    .add-layer {
        position: absolute;
        right: 142px;
        bottom: 28px;

        display: flex;
        padding-top: 4px;
        width: 130px;
        align-items: center;
        &:hover {
          cursor: pointer;
          color: $primary;
          stroke: $primary;
          fill: $primary;
        }
        svg {
          width: 24px;
          height: 24px;
          margin-top: -2px;
          margin-right: 5px;

          &,
          * {
            transition: none !important;
          }
        }
    }

    .end-layer {
        position: absolute;
        right: 28px;
        bottom: 28px;
    }

    .display-error {
        font-size: 14px;
        color: $red;
        position: absolute;
        right: 12px;
        top: 12px;
    }

    .reset-form {
        display: flex;
        padding-top: 24px;
        width: 300px;
        align-items: center;
        &:hover {
          cursor: pointer;
          color: $primary;
          stroke: $primary;
          fill: $primary;
        }
        svg {
          width: 24px;
          height: 24px;
          margin-top: -2px;
          margin-right: 5px;

          &,
          * {
            transition: none !important;
          }
        }
    }
}

@keyframes spin {
    0% {
        tranform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
