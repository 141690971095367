@import "../../../var";

.company-pdf {
  #pdf-loading {
    text-align: center;
    height: calc(100vh - 100px);
    display: table;
    width: 100%;
    .loading-container {
      display: table-cell;
      vertical-align: middle;
    }
  }
  #archive-loading {
    padding-top: 100px;
  }
  margin-top: 50px;
  #pdf-preview {
    height: calc(100vh - 100px);
    width: 100%;
  }
  &.modal-tiny {
    padding: 40px 60px 10px;
    .icon {
      margin: auto;
      width: 100px;
      height: 80px;
      .info-image {
        margin: 0;
        width: 80px;
        height: 80px;
      }
    }
    .thumbsup-wrapper {
      text-align: center;
      margin-top: 80px;
    }
    .text {
      font-family: $fontBold;
      margin-top: 50px;
      margin-bottom: 40px;
      font-size: 14px;
    }
    .done {
      font-family: $fontBold;
      padding: 20px 0;
      text-align: center;
    }
  }

  .company-pdf-footer {
    .underline {
      text-decoration: underline !important;
    }
    position: absolute;
    bottom: 0px;
    height: 60px;
    width: 100%;
    background: #fafafa;
    box-shadow: 0px 0px 8px 1px;
    z-index: 100;
    .wrapper {
      position: relative;
      margin: auto;
      text-align: center;
      padding-left: 250px;
      top: 16px;
      .cancel-btn {
        padding: 0 10px;
        display: inline-block;
      }
      .archive {
        padding: 0 10px;
        display: inline-block;
      }
      .archive-review {
        padding: 0 10px;
        display: inline-block;
      }
    }
  }
}
