@import '../../../var';
.tag {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    background-color: $primary;
    padding: 4px 20px 3px 20px;
    text-align: center;
    line-height: 20px;
    &.red {
      background-color: $red;
    }
    &.green {
      background-color: $green;
    }
    &.grey {
      background-color: $grey;
    }
    &.blueishPurple {
      background-color: $blueishPurple;
    }
    &.darkblue{
      background-color: $darkblue;
    }
    &.pink {
      background-color: $pink;
    }
    &.yellow {
      background-color: $yellow;
    }
    &.lightBlue {
      background-color: $lightBlue;
    }
    &.orange {
      background-color: $orange;
    }
    &.brick {
      background-color: $brick;
    }
    &.lime-green {
      background-color: $lime-green;
    }
    &.strong-blue {
      background-color: $strong-blue;
    }

    &.capitalize {
      text-transform: capitalize;
    }
    &.width100p{
      width: 100%;
    }

    &.otc {
      margin: 0 0 0 5px!important;
      position: relative;
      top: -7px;
    }

    &.scaRiskFlag {
      background: #fff;
      border: 1px solid #4680ff;
      color: #4680ff;
      display: block;
      font-weight: 700;
      margin: 0;
      padding: 4px 12px 3px;
    }

    &.scaStatus{
      background: #333;
      border: none;
      border-radius: 1px;
      color: #fff;
      display: block;
      margin: 0;
      padding: 4px 8px;
      text-transform: uppercase;
      width: auto;
    }

    &.newsEvents {
      width: auto;
      margin-bottom: 10px;
      padding: 2px 8px;
      border: 1px solid #fff;
      border-radius: 3px;
      background: none;
      font-size: 1em;
      max-width: 100%;
      overflow: hidden;
    }
  }