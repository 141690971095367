@import "../../../var";

.card {
    border-radius: 3px;
    background-color: white;
    min-height: $cardHeight;
    margin-top: 6px;
    border: 1px solid #ddd;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    &:hover {
      @include small-shadow();
      border-color: $primary;
      .hovering {
        color: $primary;
      }
    }
  }