@import '../../var';

.document {
  .document-txt {
    .header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      height: 50px;
      border-bottom: 1px solid $grey;
      .left {
        float: left;
        height: 50px;
        width: 186px;
        border-right: 1px solid $grey;
        svg {
          float: left;
          margin-top: 4px;
          margin-left: 4px;
          width: 34px;
        }
        .preview {
          float: left;
          font-family: $fontBold;
          margin-top: 16px;
          margin-left: 8px;
        }
      }
      .right {
        float: right;
        img {
          margin-top: 10px;
          margin-right: 12px;
          width: 28px;
        }
      }
    }
    .content {
      margin-top: 50px;

      img {
        display: none;
      }
    }
  }
}
