@import "../../../var";

.company-header {
  padding-top: 30px;
  margin-top: 50px;
  background: #fafafa;
  border-bottom: 1px solid #ededed;

  #company-website-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    &.company-website {
      text-decoration: underline;
    }
  }
  .company-header-summary {
    #alert-warning-wrapper {
      height: 42px;
    }
    .alert-warning {
      top: 50px;
      z-index: 9100;
      position: fixed;
      padding: 10px;
      width: 100%;
      background: #f19e38;
      color: black;
      padding-left: 10px;
      span {
        padding-left: 25px;
      }
      svg {
        height: 18px;
        width: 18px;
        position: absolute;
        top: 11px;
        fill: white;
      }
    }
    .tag {
      margin: 0 0 0 5px !important;
      position: relative;
      top: -7px;
    }

    .title {
      float: left;
      font-size: 30px;
      font-family: $fontBold;
      margin-right: 20px;
    }
    .title-sic {
      color: $grey;
      font-size: 16px;
    }
    .tag {
      margin-top: 10px;
    }
    .details {
      margin-top: 20px;
      min-height: 119px;

      .faint {
        color: $grey;
        font-size: 0.9em;
      }

      .price-delta {
        font-size: 0.6em;
        font-weight: normal;
        &.up {
          color: $green;
        }
        &.down {
          color: $pink;
        }
      }
    }
    .search-bar {
      margin-top: 20px;
      overflow: hidden;
      pointer-events: none;
      height: 50px;
      position: relative;
      display: flex;
      font-size: 1.1em;
      border: 0;
      border-radius: 3px;
      margin-bottom: 40px;
      .left {
        pointer-events: auto;
        cursor: default;
        padding: 0;
        position: absolute;
        img {
          height: 50px;
        }
      }
      input {
        outline: 0;
        pointer-events: auto;
        padding-left: 70px;
        padding-right: 100px;
        width: 100%;
        height: 100%;
        border: 1px solid $grey;
        border-radius: 3px;
      }
      .right {
        cursor: pointer;
        pointer-events: auto;
        line-height: 50px;
        position: absolute;
        right: 0;
        width: 160px;
        text-align: center;
        color: $grey;
        font-family: $fontBold;
        text-transform: uppercase;
        text-decoration: none;
        border: 0;
        border-left: 1px solid $grey;
        &:active,
        &:hover {
          background: $alfaPrimary;
        }
      }
      &:hover {
        border: 0;
        input {
          border-color: $primary;
        }
        .right {
          color: $primary;
        }
      }
    }
  }
  .company-header-menu {
    padding-top: 8px;
  }
}
