.appaBarChart {
  .topText {
    font-size: 12px;
  }
  font-family: "PT Mono";
  padding-top: 15px;
  & > div {
    & > div {
      margin-top: 1px;
      &.focus {
        margin-top: 0px;
        box-shadow: 1px 1px 1px #aaa;
        transition: opacity 0.1s, margin-top 0.1s, margin-bottom 0.1s;
        -webkit-transition: opacity 0.1s, margin-top 0.1s, margin-bottom 0.1s;
      }
    }
  }
}
