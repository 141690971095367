@import "../../../var";

.company-financial {
  .financial-tabs {
    margin-bottom: 25px;
  }
  padding-top: 40px;
  .panel-body {
    h3 {
      margin-top: 30px;
    }

    td {
      padding: 20px 15px;
      vertical-align: middle;
    }

    td:first-child {
      width: 50%;
    }

    &.ratios {
      .table {
        th:not(:first-child) {
          width: 12.5%;
        }
        td:last-child {
          padding-left: 75px;
          text-align: left;
        }
        th:last-child {
          text-align: left;
          padding-left: 75px;
        }
      }
    }
    &.debt {
      form {
        margin-bottom: 30px;
      }

      .label-x-axis {
        text-align: center;
        font-size: 0.9em;
      }

      .label-y-axis {
        padding-bottom: 10px;
        font-size: 0.9em;
      }

      label {
        display: flex;
        align-items: center;
        color: $grey;
        font-size: 0.9em;
        line-height: 0.9em;
        margin-bottom: 10px;
        .block {
          margin: 0 5px 0 0;
          width: 15px;
          height: 15px;
          &.blue {
            background-color: #2a2d7e;
          }
          &.light-blue {
            background-color: #42b6e2;
          }
          &.pink {
            background-color: $pink;
          }
        }
      }
    }
  }
}
