@import "../../../var";

@mixin col-half($number) {
  width: (8.33333333% * $number) + 4.16666665%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
#stock-datasource {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 13px;
  span {
    text-decoration: underline;
  }
}
.company-overview {
  padding-top: 40px;

  input[type="checkbox"]:checked:before {
    width: 29px;
    height: 29px;
    top: -8px;
    left: -7px;
  }

  h2.inline {
    display: inline-block;
    margin-bottom: 10px;
  }

  .panel-body {
    position: relative;

    &.stock-chart {
      h2:first-child {
        margin-bottom: 20px;
        padding-bottom: 20px;
        &.loaded {
          border-bottom: 1px solid #ddd;
        }
      }

      h2.drops-and-ups {
        margin-top: 20px;
        padding-top: 20px;
      }

      .legend {
        position: absolute;
        top: 96px;
        left: 20px;
        z-index: 1;
        label {
          font-weight: normal;
          input {
            margin-right: 8px;
            position: relative;
            top: -16px;
            height: 0;
          }
          span.color {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
          span.label-data {
            position: relative;
            display: inline-block;
            top: -2px;
            color: #aaa;
          }
          &:nth-of-type(1) span.color {
            background: $blueishPurple;
          }
          &:nth-of-type(2) span.color {
            background: $orange;
          }
          &:nth-of-type(3) span.color {
            background: $lightBlue;
          }
          &:nth-of-type(4) span.color {
            background: $primary;
          }
        }
      }

      .menu-tab {
        position: absolute;
        width: 400px;
        top: 95px;
        left: 220px;
        z-index: 1;
      }

      .trading-volumes {
        position: absolute;
        left: 50%;
        width: 50%;

        .term {
          color: #aaa;
          font-size: 0.9em;
        }

        .value {
          color: #000;
          font-family: $fontBold;

          &.core {
            font-size: 1.2em;
          }
        }

        span {
          margin-left: 5px;
          font-size: 0.7em;
          &.positive {
            color: $green;
          }
          &.negative {
            color: $orange;
          }
        }
      }

      .recharts-cartesian-axis-line {
        stroke: #e0e0e0;
      }

      .recharts-tooltip-wrapper {
        // display: none;

        // opacity: 0.8;
      }
      #overview-stockchart {
        svg {
          padding: 4px;
          overflow: visible;
        }
      }
    }

    &.financial {
      .col-xs-9 {
        margin-top: -44px;
      }
      .menu-tab {
        margin-top: 14px;
        margin-left: 30px;
      }

      form {
        margin-top: 40px;
      }
      h4 {
        color: $grey;
        margin-top: -10px;
      }
      label {
        display: flex;
        align-items: center;
        color: $grey;
        line-height: 0.9em;
        margin-bottom: 10px;
        span {
          font-size: 0.9em;
        }
        .block {
          margin: 0 5px 0 0;
          width: 15px;
          height: 15px;
          hr {
            margin-top: 6px;
            border: 1px solid black;
          }
          &.blue {
            background-color: $primary;
          }
          &.yellow {
            background-color: $yellow;
          }
          &.blueishPurple {
            background-color: $blueishPurple;
          }
          &.pink {
            background-color: $pink;
          }
        }
      }
    }
    &.eps {
      .eps-legend {
        padding: 5px 0;

        .blob {
          display: inline-block;

          span {
            position: relative;
            color: #aaa;
            font-size: 0.9em;
          }
          span:before {
            position: relative;
            top: 2px;
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 6px;
            line-height: 14px;
            border-radius: 100%;
            border: 1px solid #2a2d7e;
            background: #2a2d7e;
          }
        }
        .blob.estimated span:before {
          border: 2px solid #9b9b9b;
          background: none;
        }
        .blob.actual {
          margin-left: 15px;
        }
      }

      .recharts-wrapper {
        left: -30px;
      }
      .yAxis .recharts-cartesian-axis-line {
        stroke: none;
      }

      .yAxis .recharts-cartesian-axis-ticks {
        transform: translate(-5px, 0px);
      }

      .xAxis .recharts-cartesian-axis-ticks g:last-child {
        opacity: 0.5;
      }
    }
  }

  .company-card-container {
    height: 110px;
    margin-top: 1em;
  }

  .company-fact,
  .holder {
    h3 {
      margin-top: 0.25em;
    }
    p {
      color: $grey;
    }
  }

  .company-facts {
    padding-top: 5px;
    padding-bottom: 12px;
    overflow: hidden;
    // overflow-y: visible;
    display: inline-block;
    width: 100%;

    .info {
      max-width: 14.666667%;
      .info-card-container .info-card {
        // display: table;
        & > div {
          // width: inherit;
          // display: table-cell;
          display: inline-block;
          vertical-align: middle;
        }
        .value {
          margin-top: 0px;
          overflow: hidden;
        }
      }
    }
  }

  .recharts-cartesian-axis-tick tspan {
    font-size: 0.8em;
  }

  .info {
    padding: 0;
    margin-left: 8px;
    margin-bottom: 8px;
    .info-card-container {
      .info-card {
        padding: 10px;
        .value {
          margin-top: 15px;
          font-size: 1.4em;
          font-family: $fontBold;
        }
        .value-description {
          color: $grey;
          font-size: 0.8em;
        }
      }
    }
  }

  .table.key-stats {
    td:nth-child(1) {
      width: 50%;
    }
    th,
    td {
      text-align: left;
    }
    th:nth-child(2),
    td:nth-child(2) {
      padding-right: 100px;
    }

    td {
      padding: 20px 15px;
      vertical-align: middle;
    }
  }

  .sec-item {
    font-family: $fontMedium;
    cursor: pointer;
    small {
      font-family: $fontMedium;
      color: $grey;
    }
    .sec-icon {
      @include flex(unset, $cardHeight - 2);
      width: 32px;
    }
    .sec-icon-two {
      @include flex(unset, $cardHeight - 2);
      width: 32px;
    }
    .sec-name {
      @include flex(1, $cardHeight - 2);
      font-family: $fontBold;
      margin-left: 20px;
      margin-right: 20px;
    }
    .sec-freq {
      @include flex(unset, $cardHeight - 2);
      width: 100px;
    }
    .sec-date {
      @include flex(unset, $cardHeight - 2);
      width: 100px;
    }
    .sec-right-icon {
      @include flex(unset, $cardHeight - 2);
      width: 30px;
    }
    &:hover {
      .hovering {
        .change-color-in-this-element {
          fill: $primary;
        }
      }
    }
  }
}
