@import '../../var';

.user-note {
  position: fixed;
  width: $user-note-width;
  top: 50px;
  bottom: 0px;
  right: 0px;
  z-index: -10;
  background: white;
  opacity: 0;
  transition: opacity 0.3s, width 0.3s;

  &.open {
    width: $user-note-width;
    z-index: 10;
    opacity: 1;
  }

  &:not(.open) {
    pointer-events : none;
  }

  .submission-detail {
    box-shadow: 0px 13px 31px 4px rgba(0, 0, 0, 0.2);
    background: #fafafa;
    border-bottom: 1px solid #dddddd;
    padding: 20px;

    .header {
      margin-bottom: 24px;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #9b9b9b;
      }
    }

    .common-element.button {
      display: inline-block;
    }

    .detail {
      margin-top: 13px;
      font-size: 15px;
      .name {
        display: inline-block;
        color: #9b9b9b;
        width: 200px;
      }
      .detail .value {
        font-size: 16px;
      }
    }
  }

  .editor {
    box-shadow: -24px -4px 31px -20px rgba(0, 0, 0, 0.2);
    position: absolute;
    width : 100%;
    top: 276px;
    bottom: 0;
    border-bottom: 1px solid #d7d7d7;

    > div {
      border-radius : 0;
    }

    > div:nth-of-type(1) {
      height: calc(100% - 62px);
      > div:nth-of-type(1) {
        height: 42px;
        padding-top: 6px;
        overflow: hidden;
      }
      > div:nth-of-type(2) {
        height: calc(100% - 52px);
        > div:nth-of-type(1) {
          height: calc(100%);
        }
      }
    }
    .footer{
      box-shadow: -14px 10px 31px -10px rgba(0, 0, 0, 0.2);
      padding: 5px 20px;
    }
  }
}
