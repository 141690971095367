.toggle {
  .inputLabel {
    color: #888888;
  }
  font-weight: 100;
  font-size: 0.85em;
  .left,
  .right {
    position: relative;
    &:hover {
      z-index: 10;
    }
  }
  .left {
    display: inline-block;
    button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .right {
    display: inline-block;
    button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    border: 1px solid transparent;
    margin-left: -152px;
    margin-top: 24px;
    z-index: -10;
    &[required=""] {
      opacity: 0;
    }
  }
}
