@import "../../../var";

@mixin settlement-table-td {
  td {
    &.xlow {
      background-color: $gold;
    }
    &.low {
      background-color: $dark-green;
    }
    &.medium {
      background-color: $yellow;
    }
    &.high {
      background-color: $red;
    }
  }
}
.company-pricing {
  padding-top: 40px;

  .excel-download-btn {
    color: black;
    font-weight: 400;
    font-size: 13px;
    font-family: "HKGroteskRegular";
  }

  .dynamic-table {
    table.fixedHeader {
      tbody {
        max-height: 670px;
      }
    }
  }

  .button-container {
    & > div {
      display: inline-block;
    }
  }

  .dynamic-table {
    th {
      text-align: left !important;
    }
  }
  .col-xs-3 label {
    &,
    & input {
      width: 100%;
    }
  }

  .common-element.select .Select .Select-placeholder {
    left: 0;
  }

  .panel-body {
    &.top-risk {
      .table {
        margin-bottom: 0;
        th {
          &:first-child {
            width: 380px;
          }
        }
        tr:first-child {
          td {
            border-top: none;
          }
        }
        td {
          vertical-align: middle;
          &:first-child {
            width: 380px;
            .main-td-content {
              font-family: "HKGroteskSemiBold";
              font-size: 20px;
            }
            .sub-td-content {
              color: #000000;
              font-size: 12px;
            }
          }
        }
      }
      .plus {
        color: $dark-green;
      }
      .minus {
        color: $red;
      }
      .dynamic-table {
        td {
          padding: 15px 20px 15px 15px;
          vertical-align: middle;
        }
        th:last-child,
        td:last-child {
          width: 500px;
        }

        th,
        td {
          &:nth-child(2) {
            width: 350px;
            padding-right: 50px;
          }
        }
      }
    }
    &.coverage {
      .excel-download-btn {
        margin-left: 10px;
      }
      table {
        margin-bottom: 10px;
        th,
        td {
          text-align: left;
        }
        td {
          padding-left: 8px !important;
          vertical-align: middle;
          &:last-child {
            font-size: 1.5em;
            padding: 0;
            width: 24px;
            line-height: 1em;
            img:hover {
              cursor: pointer;
            }
          }
        }
      }
      .add-layer {
        display: flex;
        padding-top: 4px;
        width: 130px;
        align-items: center;
        &:hover {
          cursor: pointer;
          color: $primary;
          stroke: $primary;
          fill: $primary;
        }
        svg {
          width: 24px;
          height: 24px;
          margin-top: -2px;
          margin-right: 5px;

          &,
          * {
            transition: none !important;
          }
        }
      }
      .submit {
        margin-top: 16px;
      }
      label {
        font-weight: normal;
      }
      input {
        padding: 3px 5px;
        border: 1px solid #ddd;
        border-radius: 3px;
        &:focus {
          outline: none;
          border: 1px solid #4680ff;
        }
      }
      .select input {
        border: none;
      }

      .row.top-row {
        margin-bottom: 1em;

        label {
          div {
            color: #888888;
            font-size: 13.6px;
          }
        }
      }
    }
    &.actual-premium {
      .info {
        padding: 0;
        margin-left: 16px;
        .info-card-container {
          .info-card {
            padding: 15px;
            .value {
              font-size: 1.4em;
              font-family: $fontBold;
            }
            .value-description {
              color: $grey;
              font-size: 0.9em;
            }
          }
        }
      }
    }
    &.expiring {
      padding-bottom: 10px;

      .col-xs-3 span {
        font-family: $fontBold;
      }
      .date-picker {
        margin-bottom: 20px;
      }
      .dynamic-table {
        margin-top: 20px;

        .table {
          th,
          td {
            text-align: left;
          }
          th {
            padding-left: 0;
          }
          td {
            padding-left: 0 !important;
            height: 100px;
          }
        }
      }
    }
    &.attachments {
      th,
      td {
        text-align: left;
      }
      th {
        white-space: nowrap;
        padding-left: 0;
      }

      .ipo {
        td {
          &:first-of-type {
            width: 340px;
          }
          width: auto;
        }
      }

      .div-table-header {
        width: 14.28%;
      }

      .fixed-header-table {
        margin-bottom: 0 !important;
        th {
          text-align: left;
          width: 14.28%;
          padding-left: 0 !important;
          padding-right: 0;
        }
      }
      .hitting-layer-table {
        clear: both;
        overflow-y: auto;
        height: 200px;
        .dynamic-table {
          text-align: left;
          td {
            padding-left: 5px !important;
            padding-right: 5px;
            text-align: left;
          }
          tbody {
            tr:first-child {
              td {
                border: none;
              }
            }
            td:nth-child(2) {
              font-family: "HKGroteskSemiBold";
            }
            td {
              width: 14.28%;
            }
            @include settlement-table-td;
          }
        }
        &.hitting-layer-table-pdf {
          height: auto;
        }
      }
      .ipo {
        .fixed-header-table {
          margin-bottom: 0 !important;
          th {
            &:first-of-type {
              width: 14.28%;
            }
            width: auto;
          }
        }
        .hitting-layer-table {
          .dynamic-table {
            tbody {
              tr td {
                &:first-of-type {
                  width: 14.28%;
                }
                width: auto;
              }
            }
          }
        }
      }

      h3 {
        font-size: 1em;
      }

      .legend {
        margin-bottom: 15px;
        .item {
          display: inline-flex;
          align-items: center;
          margin: 0 30px 0 0;
          .block {
            margin: 0 5px 0 0;
            width: 18px;
            height: 18px;
            display: inline-block;
            &.xlow {
              background-color: $gold;
            }
            &.low {
              background-color: $dark-green;
            }
            &.medium {
              background-color: $yellow;
            }
            &.high {
              background-color: $red;
            }
          }
        }
      }

      .settlement-table {
        table {
          @include settlement-table-td;
        }
      }
    }
    &.industry-sca-filing {
      label {
        position: relative;

        .info {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .row {
        &.industry-chart {
          margin-top: 8px;
          .legend {
            & > div {
              text-align: center;
            }
          }
        }

        .legend {
          margin-bottom: 15px;
          .item {
            align-items: center;
            margin: 0 30px 0 0;
            .block {
              margin: 0 5px 0 0;
              width: 18px;
              height: 18px;
              position: relative;
              display: inline-block;
              top: 3px;
              &.blueishPurple {
                background-color: $blueishPurple;
              }
              &.pink {
                background-color: $pink;
              }
              &.light-green {
                background-color: $light-green;
              }
            }
          }
        }
      }
      #date-filter {
        display: inline-block;
        position: absolute;
        padding-top: 20px;
        .menu-tab-item {
          display: inline-block;
        }
      }
    }
  }
  .program-table {
    @include settlement-table-td;
  
  }
}
