@import "../../../var";

.privateHeader {
  padding-top: 30px;
  margin-top: 50px;
  background: #fafafa;
  border-bottom: 1px solid #ededed;


  .title {
    font-size: 30px;
    font-family: $fontBold;
    margin-right: 20px;
  }

  .titleSic {
    color: $grey;
    font-size: 16px;
  }

  .details {
    margin-top: 20px;
    min-height: 119px;

    .faint {
      color: $grey;
      font-size: 0.9em;
    }

    .price-delta {
      font-size: 0.6em;
      font-weight: normal;
      &.up {
        color: $green;
      }
      &.down {
        color: $pink;
      }
    }
  }
}
