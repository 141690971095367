@import "~react-day-picker/lib/style.css";
@import "~react-select/dist/react-select.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "var";
@import url(https://fonts.googleapis.com/css?family=PT+Mono);

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  &:active {
    width: 10px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: grey;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $background;
  font-family: $fontNormal;
  font-size: 1em;

  .content {
    max-width: 1800px;
    margin: auto;
    padding-left: 12px;
    padding-right: 12px;
  }

  .bold {
    font-weight: bold;
  }

  @media (min-width: 1320px) {
    .container {
      width: 1320px;
    }
  }
  @media (max-width: 1200px) {
    .container {
      width: 100%;
    }
  }

  .clearfix {
    overflow: auto;
  }

  .panel-body {
    padding: 20px 16px;
  }

  .with-spinner {
    position: relative;
    min-height: 120px;
  }

  .modal-tiny,
  .modal-large,
  .modal-small {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    overflow: visible;
    padding: 20px;
  }
  

  .modal-tiny {
    width: 440px;
    min-height: 340px;
    transform: translate(-220px, -180px);
  }

  .modal-small {
    width: 600px;
    transform: translate(-300px, -310px);
  }

  .modal-large {
    top: 10%;
    left: 10%;
    min-width: 80%;
    overflow: auto;
    max-height: 80%;
  }
 

  .modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1000;
  }

  table.table { 
    &.last {
      margin-bottom: 0;
    }
    thead {
      color: $grey;
      font-size: 0.75em;
      font-family: $fontLight;
      tr {
        height: 25px;
        th {
          &:not(:first-child) {
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        height: 40px;
        td {
          position: relative;
          &:first-child {
            font-family: $fontBold;
          }
          &:not(:first-child) {
            text-align: right;
          }
        }
        &.total {
          border-top: 2px solid $grey;
          font-family: $fontBold;
        }
      }
    }
  }

  .panel-body h2 {
    color: $grey;
    font-size: 1.4em;
    font-weight: 300;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $fontBold;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  h1 {
    font-size: 2em;
    margin-bottom: 25px;
  }
  h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.85em;
  }
  h6 {
    font-size: 0.7em;
  }

  svg {
    &.flag-image {
      width: 30px;
      height: 30px;
    }
    &.info-image {
      width: 13px;
      height: 13px;
      margin-left: 3px;
    }
  }
  form {
    label {
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }
  input[type="checkbox"] {
    margin: 0 15px 0 0;
    border-radius: 3px;
    position: relative;
    top: -1px;

    transition: 0s;

    &:before {
      transition: all 0.3s ease;
      position: absolute;
      display: block;
      height: 14px;
      width: 15px;
      border: 1px solid #aaa;
      content: "";
      background: white;
      border-radius: 3px;
      top: 0px;
    }
    &:hover,
    &:focus {
      &:before {
        border: 1px solid $primary;
        outline: none;
        transition: all 0s !important;
      }
    }
    &:checked {
      &:before {
        background: url("../public/images/checkbox.svg") no-repeat 0 0px;
        background-size: contain;
        position: relative;
        border: 1px solid transparent;
        width: 27px;
        height: 27px;
        top: -7px;
        left: -6px;
      }
    }
  }

  input::placeholder {
    color: #888888;
  }

  table > thead > tr > th {
    border-bottom: 1px solid #ddd !important;
    font-size: 1.15em !important;
    padding-bottom: 5px !important;
    font-family: $fontLight;
  }

  a {
    cursor: pointer;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }
}
@font-face {
  font-family: "HKGroteskRegular";
  src: url("../public/fonts/HKGrotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskItalic";
  src: url("../public/fonts/HKGrotesk-Italic.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskBold";
  src: url("../public/fonts/HKGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskBoldItalic";
  src: url("../public/fonts/HKGrotesk-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskLight";
  src: url("../public/fonts/HKGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskLightItalic";
  src: url("../public/fonts/HKGrotesk-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskMedium";
  src: url("../public/fonts/HKGrotesk-Medium.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskMediumItalic";
  src: url("../public/fonts/HKGrotesk-MediumItalic.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskSemiBold";
  src: url("../public/fonts/HKGrotesk-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "HKGroteskSemiBoldItalic";
  src: url("../public/fonts/HKGrotesk-SemiBoldItalic.otf") format("opentype");
}

#company-container {
  &.note-open {
    padding-right: $user-note-width;
    .container {
      width: auto !important;
      max-width: 1320px;
    }
  }
}

.peer-group-risk-predicator {
  .recharts-wrapper {
    padding-left: 4%;
  }
}


#privateSubmissionForm{

  input, .Select{
      width: 130px;
  }
  th{
    font-size: 1em !important;
    padding: 5px;
  }
}
