@import '../../../var';

.company-trade {
  padding-top: 40px;

  h2.inline {
    display : inline-block;
    margin-bottom : 10px;
  }

  .panel-body {
    &.earning {
      td:first-child {
        width : 33.667%;
      }
    }
    &.analyst {
      label {
        display: flex;
        align-items: center;
        color: $grey;
        font-size: 0.9em;
        line-height: 0.9em;
        .block{
          margin: 0 5px 0 0;
          width: 15px;
          height: 15px;
          &.blue {
            background-color: $primary;
          }
          &.red{
            background-color: $pink;
          }
        }
      }
      .risk-predictor {
        .step-label {
          .step-label-value {
            margin-right: 5px;
            margin-left: 0;
          }
        }
      }
    }
    &.holders {
      td, th{
        vertical-align: middle;
      }
      .info {
        padding: 0;
        margin-left: 5px;
        .info-card-container .info-card {
          display: table;
          padding: 15px;
          & > div {
            display: table-cell;
            vertical-align: middle;
          }
          .value {
            margin-top: 0px;
            font-size: 1.4em;
            font-family: $fontBold;
          }
          .value-description {
            color: $grey;
            font-size: 0.9em;
          }
        }
        // .info-card-container {
        //   .info-card {
        //     padding: 15px;
        //     .value {
        //       font-size: 1.4em;
        //       font-family: $fontBold;
        //     }
        //     .value-description {
        //       color: $grey;
        //       font-size: 0.9em;
        //     }
        //   }
        // }
      }
      .menu-tab {
        margin-top: 30px;
        margin-bottom: 25px;
      }
      .legend {
        display: flex;
        align-items: center;
        .flag-image {
          width: 25px;
          height: 25px;
        }
        .value {
          font-size: 0.8em;
          line-height: 25px;
          display: inline;
        }
      }
      table {
        .with-flag {
          padding-top: 0;
          padding-bottom: 0;
          display: flex;
          align-items: center;
          color : $red;
        }
        tr:first-child td {
          border-top : 0;
        }
      }
      .table {
        tr:first-child td {
          border-top: 1px solid #ddd;
        }
        th {
          border-bottom: 0 !important;
        }
        td {
          font-size : 0.85em !important;
        }
        &.direct {
          &>tbody{
            &>tr:first-child td {
              border-top: 1px solid #ddd;
            }
          }
          
          td{
            padding: 8px 10px;
          }
          th:nth-child(2), td:nth-child(2), th:nth-child(3), td:nth-child(3), th:nth-child(8), td:nth-child(8) {
            text-align: left;
          }
          th:nth-child(3), td:nth-child(3), th:nth-child(8), td:nth-child(8) {
            white-space: nowrap;
          }
        }
        &.transaction {
          th:nth-child(2), td:nth-child(2) {
            text-align: left;
          }
        }
        &.institution {
          th:nth-child(4), td:nth-child(4) {
            text-align: left;
          }
        }
      }
    }
    &.proxy {
      .proxy-item {
        font-family: $fontMedium;
        .proxy-icon {
          @include flex(unset, $cardHeight - 2);
          width: 32px;
        }
        .proxy-name {
          @include flex(unset, $cardHeight - 2);
          font-family: $fontBold;
          width: 160px;
          margin-left: 20px;
          margin-right: 20px;
        }
        .proxy-flag {
          font-family: $fontLight;
          @include flex(1, $cardHeight - 2);
          div.negative {
            color: $red;
          }
          div {
            svg {
              width: 8px;
              margin-right: 10px;
              margin-bottom: 2px;
              circle {
                fill: #ddd;
              }
            }
          }
        }
        .proxy-date {
          @include flex(unset, $cardHeight - 2);
          width: 100px;
          small {
            color: $grey;
          }
        }
        .proxy-right {
          @include flex(unset, $cardHeight - 2);
          width: 30px;
        }
        &:hover {
          .hovering {
            .change-color-in-this-element {
              fill: $primary;
            }
          }
        }
      }
    }
  }
}
