@import "../../var";

.text-underline {
  text-decoration: underline;
}

.form-disabled {
  input,
  .Select-control {
    background: #efefef;
    pointer-events: none;
  }
}

.inner-container {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.cursor-pointer {
  cursor: pointer;
}

.bad-actors-table {
  .dynamic-table {
    td {
      &:nth-child(2) {
        word-break: break-all;
      }
    }
  }
  .has-risks {
    cursor: pointer;
  }
  .dynamic-table {
    .main-td-content {
      ul {
        padding-top: 10px;
        padding-left: 15px;
      }
    }
    td {
      border-top: 1px solid #ddd;
    }
    td,
    th {
      padding-left: 10px !important;
      text-align: left !important;
    }
  }

  .bad-actor-highlited {
    border: 1px solid $primary;
    td {
      border-top: 1px solid $primary !important;
    }
    border-bottom: 0;
  }
  .bad-actor-highlited3 {
    border-left: 1px solid $primary;
    border-right: 1px solid $primary;
  }
  .bad-actor-highlited2 {
    td {
      background: #fafafa;
    }
    & > td {
      border-bottom: 1px solid $primary !important;
    }

    border-left: 1px solid $primary;
    border-bottom: 1px solid $primary;
    border-right: 1px solid $primary;
  }
  .hovering {
    &:hover {
      border-color: $primary;
    }
  }
}
.common-element {
  .input-label {
    color: #888888;
  }

  &.empty-data {
    height: 100px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background: #f0f0f0;
    line-height: 100px;
    text-align: center;
    color: #aaa;
  }

  &.user-menu {
    cursor: default;
    .overlay {
      position: fixed;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
    .wrapper {
      @include shadow();
      position: absolute;
      top: 54px;
      left: -162px;
      width: 200px;
      background-color: white;
      z-index: 10;
      padding: 12px;
      .user-name {
        display: flex;
        align-items: center;
        .profile-image {
          margin-right: 12px;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        .name {
          flex: 1;
        }
      }
      .item {
        &.border-top {
          border-top: 1px solid $grey;
          padding: 16px 0;
        }
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }
    }
  }

  &.input {
    font-weight: 100;
    font-size: 0.85em;
    .wrapper {
      position: relative;
      input {
        width: 300px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 3px;
        padding: 6px 5px 4px;
        &:focus {
          outline: none;
          border: 1px solid #4680ff;
        }
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px white inset;
        }
      }
      .error {
        font-size: 12px;
        color: $red;
        &.underline {
          background-color: $red;
        }
      }
      .long {
        width: 330px;
      }
    }
  }

  &.button {
    &.clicked {
      button,
      button:hover {
        background-color: $alfaPrimary;
        border-color: $primary;
        color: $primary;
      }
    }
    button {
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
      border-color: black;
      border-radius: 3px;
      min-width: 150px;
      font-size: 13px;
      padding: 4px 10px 4px 10px;
      outline: none;
      &:hover {
        background-color: transparent;
        border-color: $primary;
        color: $primary;
      }
      &:active {
        background-color: $alfaPrimary;
        border-color: $primary;
        color: $primary;
      }
      &[disabled] {
        border-color: $grey;
        color: $grey;
        &:hover {
          background-color: white;
        }
      }
    }
    .border-gray{
      border-color: #ddd;
    }
  }

  &.info {
    display: inline;
    position: relative;
    white-space: pre-line;
    &:hover:after {
      @include shadow();
      transform: translateX(-50%);
      color: white;
      background: $primary;
      border-radius: 3px;
      top: 30px;
      content: attr(text);
      padding: 16px;
      position: absolute;
      z-index: 98;
      width: 220px;
    }

    &:hover:before {
      border: solid;
      border-color: transparent transparent $primary transparent;
      border-width: 10px 10px 10px 10px;
      top: 10px;
      content: "";
      position: absolute;
      z-index: 99;
    }
  }

  &.sca-filing {
    .sca-doc-svg {
      svg {
        width: 27px;
      }
    }
    .sca-filings-table {
      border-collapse: separate;
      border-spacing: 0 10px;

      tbody {
        border-spacing: 0 10px;
      }
      width: 100%;
      tr {
        &.no-style {
          td {
            cursor: auto;
          }
        }
        &:not(.no-style) {
          &:hover {
            box-shadow: 2px 2px 14px -2px rgba(0, 0, 0, 0.5);
            transform: translate(-1px, -1px);
            td {
              border-color: #4680ff;
            }
          }
          &:hover {
            .hovering {
              circle {
                fill: #4680ff;
              }
            }
          }
        }
      }
      td {
        cursor: pointer;
        background-color: white;

        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        vertical-align: middle;
        padding: 16px 10px;
        small {
          font-family: "HKGroteskMedium";
          color: #aaaaaa;
        }

        &:first-child {
          border-radius: 3px;
          border-left: 1px solid #ddd;
          padding-left: 16px;
        }
        &:last-child {
          border-radius: 3px;
          border-right: 1px solid #ddd;
          padding-right: 16px;
        }
        .sca-date {
          white-space: nowrap;
        }
        &.sca-tag {
          padding: 16px 5px;
          .tag {
            padding: 4px 12px 3px 12px;
            display: block;
            margin: 0;
            font-weight: bold;
            border: 1px solid #4680ff;
            color: #4680ff;
            background: #fff;
          }
        }
        .sca-right {
          text-align: center;
          width: 30px;
        }
        .status {
          .tag {
            display: block;
            padding: 4px 8px;
            border: none;
            border-radius: 1px;
            background: #333;
            color: #fff;
            width: auto;
            margin: 0;
            text-transform: uppercase;
          }
        }
      }
    }

    .sca-status {
      @include flex(unset, $cardHeight - 2);
      font-family: $fontBold;
      width: 175px;
      text-align: center;

      .status .tag {
        border: none;
        border-radius: 1px;
        background: #333;
        color: #fff;
        width: auto;
        margin-left: 5px;
        text-transform: uppercase;
      }
    }

    div.amount {
      @include flex(unset, $cardHeight - 2);
      width: 100px;
      span {
        display: block;
      }
    }

    .sca-item {
      font-family: $fontMedium;
      small {
        font-family: $fontMedium;
        color: $grey;
      }
      .sca-icon {
        @include flex(unset, $cardHeight - 2);
        width: 32px;
      }
      .sca-name {
        @include flex(unset, $cardHeight - 2);
        font-family: $fontBold;
        width: 150px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .sca-tags {
        @include flex(1, $cardHeight - 2);
        flex-direction: row;
        min-height: 78px;
        align-items: center;
        .sca-tag {
          width: 105px;
          margin-left: 2px;
          margin-right: 2px;
        }
        div.col-md-2 {
          padding: 0 5px;
        }
        .tag-wrapper {
          display: inline-block;
          width: auto;
        }
        .tag {
          width: 100%;

          border: 1px solid $primary;
          color: $primary;
          background: #fff;
        }

        div.amount {
          padding-left: 20px;
          span {
            display: block;
          }
        }

        .status .tag {
          border: none;
          border-radius: 1px;
          background: #333;
          color: #fff;
          width: auto;
          margin-left: 5px;
          text-transform: uppercase;
        }
      }
      .sca-date {
        @include flex(unset, $cardHeight - 2);
        white-space: nowrap;
        margin: 0 20px;
      }
      .sca-right {
        @include flex(unset, $cardHeight - 2);
        width: 30px;
      }
      &:hover {
        .hovering {
          .change-color-in-this-element {
            fill: $primary;
          }
        }
      }
    }
  }

  &.risk-filing {
    .risk-item {
      font-family: $fontMedium;
      .risk-icon {
        @include flex(unset, $cardHeight - 2);
        width: 32px;
      }
      .risk-name {
        @include flex(unset, $cardHeight - 2);
        font-family: $fontBold;
        width: 160px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .risk-flag {
        font-family: $fontLight;
        @include flex(1, $cardHeight - 2);
        div.negative {
          color: $red;
        }
        div {
          svg {
            width: 8px;
            margin-right: 10px;
            margin-bottom: 2px;
            circle {
              fill: #ddd;
            }
          }
        }
      }
      .risk-date {
        @include flex(unset, $cardHeight - 2);
        width: 100px;
        small {
          color: $grey;
        }
      }
      .risk-right {
        @include flex(unset, $cardHeight - 2);
        width: 30px;
      }
      &:hover {
        .hovering {
          .change-color-in-this-element {
            fill: $primary;
          }
        }
      }
    }
  }

  &.date-picker {
    font-weight: 100;
    font-size: 0.85em;
    .wrapper {
      position: relative;
      width: 300px;

      img {
        position: absolute;
        top: 22px;
        right: 2px;
        cursor: pointer;
      }
      input {
        width: 300px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 3px;
        padding: 6px 5px 4px;
        &:focus {
          outline: none;
          border: 1px solid $primary;
        }
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px white inset;
        }
      }
      .input-group {
        margin-bottom: 18px;
      }

      .popup-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
      }
      .popup {
        position: absolute;
        display: flex;
        top: 54px;
        left: 0px;
        z-index: 100;
        .side-form {
          @include shadow();
          float: left;
          width: 190px;
          height: auto;
          margin-right: 16px;
          background-color: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 3px;
          padding: 20px;

          input {
            width: 100%;
          }
          .clear-btn {
            margin-top: 18px;
          }
          .apply-btn {
            position: absolute;
            bottom: 20px;
            width: 150px;
            button {
              width: 100%;
            }
          }
        }
        .DayPicker {
          @include shadow();
          float: right;
          background-color: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 3px;
        }

        .DayPicker-wrapper:focus {
          outline: 0;
        }
        abbr[title] {
          cursor: default;
        }
        .DayPicker-NavButton.DayPicker-NavButton--prev {
          margin-right: 170px;
        }
        .DayPicker-NavButton.DayPicker-NavButton--next {
          margin-right: 38px;
        }
        .DayPicker-Months {
          font-size: 12px;
          .DayPicker-Caption {
            text-align: center;
            & > div {
              font-size: 14px;
              padding-top: 3px;
            }
          }
        }
        .DayPicker-Week {
          border-bottom: 6px transparent solid;
        }
        .DayPicker-Day {
          padding: 6px 10px;
        }
        .DayPicker-Day--selected {
          background-color: $primary;
          color: #ffffff;
        }
      }
    }
    &:not(.single-day) .wrapper .popup {
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: #f4f4f4;
        color: #000000;
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        border-radius: 0;
      }
      .DayPicker-Day--start:not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: $primary;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
      .DayPicker-Day--end:not(.DayPicker-Day--start):not(.DayPicker-Day--outside) {
        background-color: $primary;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }

  &.select {
    font-weight: 100;
    font-size: 0.85em;
    width: 300px;
    &.width-small {
      width: 150px;
      .Select .Select-menu-outer {
        width: 150px;
      }
    }
    * {
      transition: none;
    }
    .Select {
      border-radius: 3px;
      border: 1px solid #dddddd;
      outline: none;
      &.is-focused {
        border: 1px solid #4680ff;
      }
      &.Select--single .Select-value {
        margin-top: -2px;
      }
      &.Select--multi {
        .Select-value {
          margin-top: 3px;
          max-width: 220px;
        }
        .Select-value-label {
          max-width: 200px;
        }
      }
      .Select-placeholder {
        top: -2px;
        left: -4px;
        color: #888888;
      }
      .Select-control {
        height: 29px;
        border: none;
        box-shadow: none;
        .Select-input {
          height: 29px;
          input {
            margin-left: -4px;
          }
        }
      }
      .Select-menu-outer {
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.5);
        border: 1px solid #4680ff;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        width: 300px;
        left: -1px;
        .Select-option {
          color: #333333;
        }
      }
    }
  }

  &.events-bar {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    .event {
      display: inline-block;
      width: 316px;
      padding-right: 16px;
    }

    .company-event-container {
      border: none;
      color: #fff;

      &:hover {
        cursor: pointer;
        box-shadow: 5px 5px 25px -0 #ccc;
        transform: translate(-2px, -2px);
      }

      &.up-event {
        background: $green;
      }

      &.down-event {
        background: $orange;
      }

      &.panel {
        margin-bottom: 14px;
      }

      p {
        font-size: 0.9em;
      }

      .panel-body {
        padding: 26px 16px 14px;
      }

      .common-element.tag {
        // margin-top: 9px;
        // float: right;
        margin-bottom: 10px;
        padding: 2px 8px;
        border: 1px solid #fff;
        border-radius: 3px;
        background: none;
        font-size: 1em;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
}

.dynamic-table {
  .border-top-bold{
    border-top: 2px solid black;
  }
  table.fixedHeader {
    thead {
      position: absolute;
      display: block;

      th {
        border-bottom: none !important;
      }
    }
    tbody {
      border-top: 1px solid #ddd !important;
      margin-top: 32px;
      max-height: 670px;
      overflow: auto;
      display: block;
    }
  }

  table {
    width: 100%;
    thead {
      tr {
        th {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  tr:not(.subtotal-tr) {
    td {
      padding-left: 15px;
      padding-right: 15px;
      font-family: "HKGroteskRegular" !important;
    }
  }
  tr.first-td-bold {
    td:first-child {
      font-weight: bold;
    }
  }

  tr.subtotal-tr {
    td {
      padding-left: 0px;
      border-top: 1px solid black;
      font-family: $fontBold !important;
    }
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.risk-predictor-tooltip {
  position: absolute;
  height: 0;
  left: 50%;
  z-index: 1000;
  .risk-predictor-tooltip-content {
    .close {
      width: 25px;
      height: 25px;
    }
    width: 300px;
    border-radius: 3px;
    background-color: #ffffff;

    box-shadow: 0 10px 10px 0 rgba(176, 176, 176, 0.5);
    position: relative;
    left: -50%;
    padding: 20px;

    .list-name,
    .list-percentile {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .risk-predicor-company-peer-groups-title {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 12px;
    }

    .risk-predicor-company-peer-groups {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .list-header {
        color: #9b9b9b;
        border-bottom: 1px solid #9b9b9b;
        margin: 12px 0;
        .list-name {
          width: 50%;
        }

        .list-percentile {
          width: 50%;
        }
      }
    }

    .risk-predicor-company-list {
      .list-name {
        width: 75%;
      }

      .list-percentile {
        width: 20%;
      }
      overflow: auto;
      max-height: 300px;
    }
    top: 10px;
    border-top: 1px rgba(176, 176, 176, 0.5) solid;
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 0px;
      left: 50%;
      margin-left: -11px;
      box-sizing: border-box;
      border: 8px solid black;
      border-color: #fff #fff transparent transparent;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: 1px -1px 1px 0 rgba(176, 176, 176, 0.5);
    }
  }
}

.info-card {
  position: relative;
  overflow: visible;
  min-height: 105px;
  .value {
    margin-top: 0;
    overflow: hidden;
    font-size: 1.4em;
    font-family: HKGroteskSemiBold;
  }
  .value-description {
    color: #aaa;
    font-size: 0.8em;
  }
}
