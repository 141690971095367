@import "../../var";

.userMenu {
    cursor: default;
    .overlay {
      position: fixed;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
    .wrapper {
      @include shadow();
      position: absolute;
      top: 45px;
      left: -162px;
      width: 200px;
      background-color: white;
      z-index: 10;
      padding: 12px;
      .userName {
        display: flex;
        align-items: center;
        .profileImage {
          margin-right: 12px;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        .name {
          flex: 1;
        }
      }
      .item {
        &.borderTop {
          border-top: 1px solid $grey;
          padding: 16px 0;
        }
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }
    }
  }