@import "../../var";

.app-bar {
  #feeback-wrapper {
    position: fixed;
    bottom: 0;
    right: 10px;
    background-color: $primary;
    color: white;
    a {
      padding: 8px 15px;
      display: inline-block;
    }
    #feedback-text {
      padding-left: 5px;
    }
    svg {
      position: relative;
      top: 5px;
    }
  }
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;
  z-index: 9990;

  .cursor-pointer {
    cursor: pointer;
  }
  .content {
    overflow: visible;
  }
  .left {
    display: inline-block;
    height: 50px;
    #logo {
      height: 30px;
      margin-top: 6px;
      margin-left: 8px;
      &:hover {
        cursor: pointer;
      }
    }

    div.input {
      position: absolute;
      display: inline;
      input {
        outline: 0;
        margin-left: 15px;
        border: 0;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        height: 49px;
        width: 350px;
        padding-right: 40px;
        padding-left: 10px;
        color: $grey;
        &:focus,
        &:hover {
          color: black;
        }
      }

      span.icon {
        vertical-align: middle;
        color: $primary;
        img {
          height: 50px;
          position: absolute;
          right: 0;
        }
      }
    }
  }
  .right {
    float: right;
    .wrapper {
      position: relative;
      width: 236px;
      overflow: visible;
    }
    span {
      position: absolute;
      top: 8px;
      width: 34px;
      height: 34px;
      cursor: pointer;

      &:hover > svg {
        color: #487ffc;
        stroke: #487ffc;
        fill: #487ffc;
      }

      &.submission {
        top: 16px;
        width: auto;
        height: auto;

        &:hover {
          color: #487ffc;
        }
      }
      &.profile {
        right: 8px;
        width: 30px;
        * {
          transition: all 0s !important;
        }
      }
      &.note {
        right: 70px;
        width: 30px;
        * {
          transition: all 0s !important;
        }

        &.open {
          stroke: #487ffc;
          fill: #487ffc;
        }
      }
      &.print {
        left: 108px;
        width: 30px;
      }
      &.download {
        left: 152px;
        width: 30px;
      }
      &.close {
        left: 210px;
        width: 30px;
      }
    }
  }
}
