@import "../../../var";
.menuTab {
  .uppercase {
    font-size: 18px;
    text-transform: uppercase;
  }
  &.holders {
    margin-bottom: 25px;
    margin-top: 30px;
  }
}
