@import "../../../var";

.company-news {
  padding-top: 40px;

  .panel-body {
    position: relative;
  }

  h2 + p {
    margin: 2em 0 0 0;
    font-size: 0.9em;
  }

  .events-bar {
    margin-bottom: 30px;
  }

  .news-items {
    margin-top: 5px;
    max-height: 1200px;
    overflow-y: auto;
    > p {
      margin: 1em 0;
      font-family: $fontBold;
    }
  }

  .news-item {
    clear: both;
    margin-bottom: 20px;
    overflow: hidden;

    &:hover {
      h3 {
        color: $primary;
      }
    }

    h3 {
      padding-top: 10px;
      display: inline;
    }

    img {
      float: left;
      margin-right: 20px;
      width: 100px;
      height: 100px;
    }

    span {
      display: block;
      margin-bottom: 1em;
      color: $grey;
      font-size: 0.9em;
    }
    .news-title-wrapper {
      margin-bottom: 3px;
      .news-title {
        color: #333;
        &:hover {
          color: $primary;
        }
        margin: 0;
        margin-right: 10px;
      }
      .group-category-tag {
        display: inline-block;
        color: #e8e8e8;
        background-color: #757575;
        margin: 0;
        padding: 2px 10px;
        vertical-align: middle;
        margin-right: 5px;
        font-size: 0.7em;
      }
    }

    .common-element.tag {
      margin-left: 10px;
    }
  }

  #filing8k {
    position: absolute;
    top: -20px;
  }

  form {
    &.announcements-form {
      display: flex;
    }
  }
}
