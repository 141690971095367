$primary: #4680ff;
$red: #da4f4c;
$orange: #f36539;
$green: #34d2a0;
$dark-green: #4f9d6f;
$light-green: #7fd14d;
$lime-green: #047331;
$grey: #aaaaaa;
$blueishPurple: #292d80;
$strong-blue: #2252bb;
$pink: #f74a95;
$yellow: #ffd409;
$lightBlue: #3db7e4;
$background: #fdfdfd;
$brick: #b71c1c;
$darkblue: #0f7bab;
$gold: #c8af00;
$alfaPrimary: transparentize($primary, 0.7);
$alfaLightBlue: transparentize($lightBlue, 0.7);

$fontNormal: "HKGroteskRegular";
$fontItalic: "HKGroteskItalic";
$fontBold: "HKGroteskBold";
$fontBoldItalic: "HKGroteskBoldItalic";
$fontLight: "HKGroteskLight";
$fontLightItalic: "HKGroteskLightItalic";
$fontMedium: "HKGroteskMedium";
$fontMediumItalic: "HKGroteskMediumItalic";
$fontSemiBold: "HKGroteskSemiBold";
$fontBold: $fontSemiBold; // try semi-bold instead of bold globally...
$fontSemiBoldItalic: "HKGroteskSemiBoldItalic";

$user-note-width: 360px;

@mixin small-shadow() {
  box-shadow: 2px 2px 14px -2px rgba(#000, 0.5);
  transform: translate(-1px, -1px);
}

@mixin shadow() {
  box-shadow: 5px 5px 28px -2px rgba(#000, 0.5);
  transform: translate(-2px, -2px);
}

@mixin flex($flex, $height) {
  display: flex;
  flex: $flex;
  justify-content: center;
  flex-direction: column;
  min-height: $height;
}

$cardHeight: 80px;
