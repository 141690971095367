@import '../../../var';
.menuTabItem {
  div {
    float: left;
    margin-right: 35px;
    cursor: pointer;
    padding-bottom: 10px;
    transition: none;
    &:hover {
      color: $primary;
    }
    &.active {
      color: $primary;
      font-weight: bold;
      padding-bottom: 6px;
      border-bottom: 4px solid $primary;
    }
  }
}
