@import "../../../var";

.company-create-worksheet {
  box-shadow: 0 10px 20px 0 #ddd;

  .head-wrapper {
    min-height: 165px;
    position: relative;
    background: #fafafa;
    margin: -20px;
    padding: 20px 14px 12px;
    border-bottom: 2px solid #ededed;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    .close {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 26px;
    }
    .title {
      font-size: 30px;
      font-family: $fontBold;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .tag {
      margin-bottom: 12px;
    }
    .info {
      display: table;
      & > div {
        display: table-row;
      }

      .labels {
        width: 126px;
        color: $grey;
        font-size: 0.9em;
        padding-right: 5px;
        display: table-cell;
      }
      .values {
        display: table-row;
        :nth-child(1) {
          display: block;
          width: 430px;
          color: #337ab7;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .body-wrapper {
    margin-top: 32px;
    .input-label {
      margin-top: 12px;
    }
    .eff-date-warning {
      .input-label {
        color: orange;
      }
      span {
        color: orange;
      }
      input {
        border: 1px solid orange !important;
      }
    }
  }
  .footer-wrapper {
    padding-top: 15px;
    float: right;
    bottom: 16px;
    right: 16px;
    font-size: 13px;
    .button {
      display: inline-block;
    }
    .button,
    .link {
      margin-left: 28px;
    }
  }
}
