@import "../../../var";

.link {
  display: inline;
  cursor: pointer;
  span.a,
  a {
    text-decoration: underline;
  }
  &:hover {
    span.a,
    a {
      color: #4680ff;
    }
  }
  &:visited {
    span.a,
    a {
      color: $primary;
    }
  }
  &[disabled] {
    pointer-events: none;
    span.a a {
      color: grey;
    }
  }
}